<template>
    <div class="con">
        <div class="tabs">
            <div :class="{'active':isActive}" @click="toLogin">用户登录</div>
            <el-divider direction="vertical"></el-divider>
            <div :class="{'active':!isActive}" @click="toRegister">用户注册</div>
        </div>
        <div class="tab-con" v-if="isActive">
            <div class="tabs-login">
                <div class="user-login" :class="{'active1':mobileactive}" @click="userLogin">用户登录</div>
                <div class="mobile-login" :class="{'active1':!mobileactive}" @click="mobileLogin">手机登录</div>
            </div>
            <div class="form-user-login" v-if="mobileactive">
                <input type="text" placeholder="请输入账号" class="input1" v-model.trim="uusername"/>
                <div class="findcode-con">
                    <input type="password" placeholder="请输入密码" class="input1" v-model.trim="upassword"/>
                    <div class="find-code" @click="tofindCode">忘记密码？</div>
                </div>
                
                <div class="code-con">
                    <input type="text" placeholder="图形验证码（看不清？点击右侧图片换一张）" class="input2" v-model.trim="uimagecode"/>
                    <img :src="code_img" @click="updatacodeImg"/>
                </div>
                <div class="form-login" @click="uLogin">登录</div>
                <!-- <el-checkbox v-model="checked" text-color="#999999" fill="#333">自动登录</el-checkbox> -->
            </div>
            <div v-else class="form-mobile">
                <input type="text" placeholder="手机号" class="input1" v-model.number.trim="mmobile"/>
                <div class="mobcode-con">
                    <input type="text" placeholder="手机验证码" class="input2" v-model.trim="mcode"/>
                    <button class="btn" @click="getPhoneCode(2)" :style="{color:timeflag?'#020202':'#ffffff',background:timeflag?'#DDDDDD':''}">{{buttonText}}</button>
                </div>
                <div class="code-con">
                    <input type="text" placeholder="图形验证码（看不清？点击右侧图片换一张）" class="input2" v-model.trim="mimgcode"/>
                    <img :src="code_img" @click="updatacodeImg"/>
                </div>
                <div class="form-login" @click="mobLogin">登录</div>
            </div>
        </div>

        <div v-else class="form-register">
            <input type="text" placeholder="设置账号（6-15位字母及数字）" class="input1" v-model.trim="rusername"/>
            <input type="text" placeholder="设置密码（6-15位数的密码）" class="input1" v-model.trim="rpassword"/>
            <input type="text" placeholder="手机号码" class="input1" v-model.number.trim="rmobile"/>
            <div class="mobcode-con">
                <input type="text" placeholder="手机验证码" class="input2" v-model.trim="rmobilecode"/>
                <button class="btn" @click="getPhoneCode(1)" :style="{color:timeflag?'#020202':'#ffffff',background:timeflag?'#DDDDDD':''}">{{buttonText}}</button>
            </div>
            <div class="code-con">
                <input type="text" placeholder="图形验证码（看不清？点击右侧图片换一张）" class="input2" v-model.trim="rcode"/>
                <img :src="code_img" @click="updatacodeImg"/>
            </div>
            <div class="register-btn" @click="toReg">注册</div>
            <el-checkbox v-model="regchecked">注册代表你已同意<a>《注册协议》</a></el-checkbox>
        </div>
    </div>
</template>
<script>
import {ref,onMounted,reactive,toRefs} from 'vue'
import {useRoute,useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {getData} from '@/utils/req'
import { ElMessage } from 'element-plus'
export default {
    setup(){
        let isActive = ref(true)
        const store = useStore()
        let mobileactive = ref(true)
        let state= reactive({
            code_img:'',
            code:''
        })
        let userstate =reactive({
            uusername:'',
            upassword:'',
            uimagecode:'',
            checked:false
        })
        let mobilestate = reactive({
            mmobile:'',
            mcode:'',
            mimgcode:''
        })
        let registerstate = reactive({
            rusername:'',
            rpassword:'',
            rmobile:'',
            rmobilecode:'',
            rcode:'',
            regchecked:false
        })
        let timeflag = ref(true)
        let buttonText = ref('获取验证码')
        const route = useRoute()
        const router = useRouter()
        onMounted(()=>{
            updatacodeImg()
            if(route.query.selected ==1){
                isActive.value =true
            }
            else{
                isActive.value =false
            }
        })
        const toLogin =()=>{
            isActive.value =true
            updatacodeImg()
        }
        const toRegister=()=>{
            isActive.value =false
            updatacodeImg()
        }
        const userLogin=()=>{
            mobileactive.value =true
            updatacodeImg()
        }
        const mobileLogin =()=>{
            mobileactive.value =false
            updatacodeImg()
        }
        const updatacodeImg = ()=>{
            const data={
                "module": "app_pc",
                "action": "login",
                "m": "get_code"
            }
            getData(data).then(res=>{
                state.code_img = "https://admin.iseso.net//"+res.data.code_img
                state.code = res.data.code
            })
        }
        const tofindCode =()=>{
            router.push('/login/findcode')
        }
        const uLogin=()=>{
            if(!userstate.uusername){
                ElMessage({
                    showClose: true,
                    message: '用户名不能为空',
                    type: 'error'
                })
                return;
            }
            else if(!userstate.upassword){
                ElMessage({
                    showClose: true,
                    message: '密码不能为空',
                    type: 'error'
                })
                return;
            }
            else if(!userstate.uimagecode){
                ElMessage({
                    showClose: true,
                    message: '图形验证码不能为空',
                    type: 'error'
                })
                return;
            }
            else if(state.code.toLocaleLowerCase() !== userstate.uimagecode.toLocaleLowerCase()){
                ElMessage({
                showClose: true,
                message: '验证码输入不正确',
                type: 'error'
            })
            userstate.uimagecode = ''
            updatacodeImg()
            return;
            }

            const data = {
                module:'app_pc',
                action:'login',
                m:'login',
                access_id: '',
                phone:userstate.uusername, 
                password:userstate.upassword, // 密码
                imgCode: userstate.uimagecode
            }
            getData(data).then(res=>{
                if(res.code !== 200){
                    ElMessage({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    })
                    return;
                }
                else{
                    localStorage.setItem("pc_user",JSON.stringify(res.data))
                    ElMessage({
                        showClose: true,
                        message: "登录成功",
                        type: 'success',
                        duration:1500
                    })
                    store.dispatch('updateCart')
                    router.push('/')
                }
                
            })
        }
        const checkVal = (str)=>{
            const reg = /^[\u4e00-\u9fa5_A-Za-z0-9]+$/
            if(reg.test(str)){
                return true
            }
            else{
                return false
            }
        }
        const toReg =()=>{
            if(!registerstate.rusername){
                ElMessage({
                    showClose: true,
                    message: '用户名不能为空',
                    type: 'error'
                })
                return;
            }
            var reg = /^(?![^A-z]+$)[A-z\d]{6,15}$/
            if(!reg.test(registerstate.rusername)){
                ElMessage({
                    showClose: true,
                    message: '请输入账号为6-15位字母或数字',
                    type: 'error'
                })
                return;
            }
            if(!checkVal(registerstate.uusername))
            {
                ElMessage({
                    showClose: true,
                    message: '账号中有特殊符号',
                    type: 'error'
                })
                return;
            }
            if(!registerstate.rpassword){
                ElMessage({
                    showClose: true,
                    message: '密码不能为空',
                    type: 'error'
                })
                return;
            }
            if(!checkVal(registerstate.upassword))
            {
                ElMessage({
                    showClose: true,
                    message: '密码中有特殊符号',
                    type: 'error'
                })
                return;
            }
            if(registerstate.rpassword.length <6){
                ElMessage({
                    showClose: true,
                    message: '密码最小长度为6',
                    type: 'error'
                })
                return;
            }
            if(registerstate.rpassword.includes(' '))
            {
                ElMessage({
                    showClose: true,
                    message: '密码中不能包含空格',
                    type: 'error'
                })
                return;
            }
            if(!registerstate.rmobile){
                ElMessage({
                    showClose: true,
                    message: '手机号不能为空',
                    type: 'error'
                })
                return;
            }
            var reg1= /^1[3|4|5|8][0-9]\d{4,8}$/
            if(!reg1.test(registerstate.rmobile)){
                ElMessage({
                    showClose: true,
                    message: '手机号码格式不正确',
                    type: 'error'
                })
                return;
            }
            if(!registerstate.rmobilecode){
                ElMessage({
                    showClose: true,
                    message: '手机验证码不能为空',
                    type: 'error'
                })
                return;
            }
            if(!registerstate.rcode){
                ElMessage({
                    showClose: true,
                    message: '图形验证码不能为空',
                    type: 'error'
                })
                return;
            }
            if(state.code.toLocaleLowerCase() !== registerstate.rcode.toLocaleLowerCase())
            {
                ElMessage({
                    showClose: true,
                    message: '验证码错误',
                    type: 'error'
                })
                return;
            }
            if(!registerstate.regchecked){
                ElMessage({
                    showClose: true,
                    message: '请勾选用户注册协议',
                    type: 'error'
                })
                return;
            }
            const data ={
                "module":"app_pc",
				"action":"login",
				"m":"user_register",
                "userId": registerstate.rusername,
				"phone":registerstate.rmobile, // 账号/手机号码
				"password":registerstate.rpassword, // 密码
				"imgCode": registerstate.rcode,
				"keyCode":registerstate.rmobilecode
            }
            getData(data).then(res=>{
                if(res.code ===200){
                    ElMessage({
                        showClose: true,
                        message: '注册成功',
                        type: 'success'
                    })
                router.push({path: '/login', query: {selected: 1}})
                }
                else{
                ElMessage({
                    showClose: true,
                    message:res.message,
                    type: 'error'
                    })
                    router.push({path: '/login', query: {selected: 1}})
                }
            })
        }
        const countDown = (i)=>{
            i--;
            console.log(i)
            if(i>0){
                buttonText.value = `${i}s后可重获`
                setTimeout(()=>{
                    countDown(i)
                },1000)
            }
            else{
                timeflag.value = true
                buttonText.value = '获取验证码'
            }

        }
        const getPhoneCode =(flag)=>{
            if(!timeflag.value){
                return
            }

            if(!registerstate.rmobile&&flag ===1){
                ElMessage({
                    showClose: true,
                    message: '手机号不能为空',
                    type: 'error'
                })
                return;
            }
            if(!mobilestate.mmobile&&flag ===2){
                ElMessage({
                    showClose: true,
                    message: '手机号不能为空',
                    type: 'error'
                })
                return;
            }
            timeflag.value = false

            const data={
                "module": "app",
                "action": "user",
                "app": "secret_key",
                "phone":registerstate.rmobile
            }
            getData(data).then(res=>{
                if(res.code ===200){
                    countDown(60)
                    ElMessage({
                    showClose: true,
                    message: '发送成功',
                    type: 'success'
                    })
                }
                else if(res.code == 220){
					timeflag.value = false
                    ElMessage({
                    showClose: true,
                    message: '短信发送频率超限！',
                    type: 'error'
                    })
					
				}else{
					timeflag.value = false
					ElMessage({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                })
				}
            })
            
        }
        const mobLogin =()=>{
            if(!mobilestate.mmobile){
                ElMessage({
                    showClose: true,
                    message: '手机号不能为空',
                    type: 'error'
                })
                return;
            }
            var reg1= /^1[3|4|5|8][0-9]\d{4,8}$/
            if(!reg1.test(registerstate.rmobile)){
                ElMessage({
                    showClose: true,
                    message: '手机号码格式不正确',
                    type: 'error'
                })
                return;
            }
            if(!mobilestate.mcode){
                 ElMessage({
                    showClose: true,
                    message: '验证码不能为空',
                    type: 'error'
                })
                return;
            }
            if(!mobilestate.mimgcode){
                ElMessage({
                    showClose: true,
                    message: '图形验证码不能为空',
                    type: 'error'
                })
                return;
            }
            if(mobilestate.mimgcode !== state.code){
                 ElMessage({
                    showClose: true,
                    message: '验证码错误',
                    type: 'error'
                })
                return;
            }
            const data = {
                "module":"app_pc",
                "action":"login",
                "m":"register",
                "access_id": '',
                "phone":mobilestate.mmobile, 
                "keyCode":mobilestate.mcode, // 手机验证码
                "imgCode": mobilestate.mimgcode
            }
            getData(data).then(res=>{
                if(res.code ===200){
                    ElMessage({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                })
                router.push({path:"/"})
                }
            })
        }
        return {
            isActive,
            mobileactive,
            buttonText,
            timeflag,
            ...toRefs(registerstate),
            ...toRefs(state),
            ...toRefs(userstate),
            ...toRefs(mobilestate),
            toLogin,
            toRegister,
            userLogin,
            mobileLogin,
            updatacodeImg,
            tofindCode,
            uLogin,
            toReg,
            getPhoneCode,
            mobLogin
        }
    }
}
</script>
<style scoped>
    .con{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 70px;
    }
    .tabs{
        display: flex;
        align-items: center;
        width: 420px;
    }
    .tabs div{
        font-size: 20px;
        color: #999999;
        cursor: pointer;
        margin-right: 20px;
    }
    .active{
        font-weight: bold;
        color: #27282E !important;
    }
    .tab-con{
        margin-top: 60px;
        width: 420px;
    }
    .tabs-login{
        display: flex;
        align-items: center;
    }
    .tabs-login div{
        width: 210px;
        height: 50px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 50px;
        text-align: center;
        border: 1px solid #D7D7D7;
    }
    .active1{
        background: #D4282F;
        color: #FFFFFF !important;
        border: 1px solid #d42a2fc4;
    }
    .form-user-login{
        width: 420px;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
    }
    .input1{
        width: 420px;
        height: 50px;
        border: 1px solid rgba(221,221,221,1);
        margin-top: 20px;
        padding-left: 15px;
        font: 400 13.3333px Arial;
    }
    .form-user-login .findcode-con{
        position: relative;
    }
    .form-user-login .find-code{
        position: absolute;
        position: absolute;
        top: 35px;
        right: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D4282F;
        line-height: 20px;
        cursor: pointer;
    }
    .code-con{
        display: flex;
        align-items: center;
        margin-top: 25px;
    }
    .code-con .input2{
        width: 305px;
        margin-right: 15px;
        height: 50px;
        border: 1px solid rgba(221,221,221,1);
        padding-left: 15px;
    }
    .code-con img{
        width: 100px;
        height: 46px;
        border: 1px solid rgba(238,238,238,1);
    }
    
    .form-user-login  .form-login{
        background: #D4282D;
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 40px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        margin-bottom: 15px;
    }
    .form-mobile .form-login{
        background: #D4282D;
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 40px;
        height: 50px;
        text-align: center;
        line-height: 50px;
    }
    .form-mobile .input1{
        width: 420px;
        height: 50px;
        border: 1px solid rgba(221,221,221,1);
        margin-top: 45px;
        padding-left: 15px;
        font: 400 13.3333px Arial;
    }
    .mobcode-con{
        width: 420px;
        position: relative;
        margin-top: 20px;
    }
    .mobcode-con .input2{
        width: 420px;
        height: 50px;
        border: 1px solid rgba(221,221,221,1);
        padding-left: 15px;
    }
    .mobcode-con .btn{
        position: absolute;
        right: 0;
        top: 0;
        
        background: rgb(221, 221, 221);
        width: 120px;
        height: 50px;
        background: rgba(184,184,184,1);
        border: 0;
        font-size: 16px;
    }
    .form-register{
        display: flex;
        flex-direction: column;
        width: 420px;
    }
    .form-register .register-btn{
        background: #D4282D;
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 40px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        margin-bottom: 15px;
    }
</style>